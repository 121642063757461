.clients {
  margin-top: 100px;
  padding-bottom: 50px;
}

.client__title {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 2rem;
}

.filter__widget-wrapper {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.filter__widget-01 select,
.filter__widget-02 select {
  border: none;
  padding: 7px 20px;
  border-radius: 50px;
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  margin-bottom: 2rem;
}

.car__item {
  padding: 20px;
  border-radius: 5px;
  background: var(--primary-color);
  cursor: pointer;
}

.car__item-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car__item-tile h3 {
  font-size: 1.3rem;
  color: #032c88;
  font-weight: 400;
}

.car__item-tile span i {
  font-size: 1.3rem;
  color: var(--small-text-color);
  cursor: pointer;
}

.car__item-top p {
  color: var(--small-text-color);
  font-size: 0.9rem;
  font-weight: 400;
}

.car__item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car__bottom-left {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.car__bottom-left p {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #032c88;
  font-weight: 300 !important;
}

.car__bottom-left p i {
  color: var(--secondary-color);
}

.car__rent {
  color: #032c88;
  font-size: 1.1rem;
}

.client__car-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
}

.car__item-tile h3 {
  font-size: 1rem;
  font-weight: 400;
}

.car__item-top {
  margin-bottom: 15px;
}

.car__img {
  margin-bottom: 20px;
}

.car__item:nth-child(2) .car__img img,
.car__item:nth-child(6) .car__img img,
.car__item:nth-child(8) .car__img img {
  width: 75%;
}

.car__item:nth-child(4) .car__img img {
  width: 85%;
}

.client__buttons {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
}

.client__buttons_small {
  
  padding-top: 50px;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}

.big-button {
  padding: 20px;
  font-size: 1.2rem;
  background-color: #ffffff;
  color: #686868;
  border: 2px solid #CCCCCC;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  width: 200px;
  transition: border-width 0.3s, background-color 0.3s;
}

.big-button:hover {
  border-width: 0.1px;
}

.big-button.clicked {
  background-color: #092151 !important; /* Ensure the clicked effect overrides other styles */
  color: #fff !important;
}

.send-button {
  padding: 20px;
  font-size: 1.2rem;
  background-color: #2adb65;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.client__card {
  width: 40%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.client__card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.client__card p {
  font-size: 1rem;
  margin-bottom: 5px;
}

.client__card ul {
  list-style-type: none;
  padding: 0;
}

.client__card li {
  font-size: 1rem;
  margin-left: 20px;
}

.client__content {
  display: flex;
  padding-top: 2%;
  height: 253px;
}

.client__buttons {
  width: 50%;
}

.client__card {
  height: 400px; /* Set the fixed height */
  width: 45%;
  padding: 20px;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.send-button-container {
  margin-top: auto; /* Push the button to the bottom */
  align-self: flex-end; /* Align the button to the right */
}

.small-button {
  height:70px;
  width: 200px;
  font-size: 14px;
  padding: 8px 12px;
  margin-right: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.small-button:hover {
  background-color: #f0f0f0;
}

.small-button.clicked {
  background-color: #0082ca;
  color: #fff;
  border-color: #0275b3;
}