@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --primary-color: #ffffff;
  --secondary-color: #0082ca;
  --body-bg: #F4F7F9;
  --card-01-bg: linear-gradient(#4cc0e7, #0082ca);
  --card-02-bg: linear-gradient(#0082ca, #032c88);
  --card-03-bg: linear-gradient(#4cc0e7, #0082ca);
  --card-04-bg: linear-gradient(#0082ca, #032c88);;
  --heading-color: #fff;
  --small-text-color: #808191;
  --recommend-car01-bg: linear-gradient(#092151, #032c88);
  --recommend-car02-bg: linear-gradient(#092151, #032c88);
  --recommend-car03-bg: linear-gradient(#092151, #032c88);
  --recommend-car04-bg: linear-gradient(#092151, #032c88);
  --recommend-car05-bg:linear-gradient(#092151, #032c88);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "DM Sans", sans-serif;
  background: var(--body-bg);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

.main__layout {
  margin-left: 260px;
}

img {
  width: 100%;
}
