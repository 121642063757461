.car__rent.plate {
    font-weight: bold;
    font-size: 1.1em; /* Adjust font size as needed */
  }

  .car__rent.date {
    font-weight: bold;
    font-size: 0.9em; /* Adjust font size as needed */
  }

  .car__item.selected {
    border: 0.5px solid #092151; /* Change border color as needed */
  }


  .alert {
    border: 1px solid red; /* or any style you prefer for alert */
    background-color: #ffebee; /* Light red background */
  }
