.dashboard {
  margin-top: 100px;
  padding: 0px 30px;
  padding-bottom: 50px;
}

.custom-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.single__card {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
}

.single__card:nth-child(1) {
  background: var(--card-01-bg);
}

.single__card:nth-child(2) {
  background: var(--card-02-bg);
}

.single__card:nth-child(3) {
  background: var(--card-03-bg);
}

.single__card:nth-child(4) {
  background: var(--card-04-bg);
}

.card__content h4 {
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 400;
}

.card__content span {
  color: var(--heading-color);
  font-size: 2rem;
}

.card__icon {
  font-size: 2rem;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.845);
}

.dashboard__cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 2rem;
}

.statics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  margin-top: 2rem;
}

.stats {
  background: var(--primary-color);
  padding: 30px;
  border-radius: 5px;
  height: 250px;
  padding-bottom: 50px;
}

.tooltip__style {
  background: var(--body-bg) !important;
  color: #fff;
}

.stats__title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.recommend__cars-wrapper {
  display: flex;
  overflow-x: auto;
  margin-top: 3rem;
  padding-bottom: 20px; /* Add space for the scrollbar */
  scrollbar-width: thin; /* "auto" will only apply styles in webkit */
  scrollbar-color: #a8a8a8 transparent; /* color of the scrollbar thumb and track */
}

.recommend__cars-wrapper::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.recommend__cars-wrapper::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Radius of the scrollbar thumb */
}

.recommend__cars-wrapper::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}

.recommend__car-card {
  padding-top: 100px;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 400px; /* Fixed width for each card */
  margin: 10px;
}

.recommend__car-card:last-child {
  margin-right: 0; /* Remove o espaçamento do último card */
}

.recommend__car-card {
  background: var(--recommend-car01-bg);
}


.recommend__car-top h5 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  color: var(--body-bg);
  margin-bottom: 10px;
  font-size: 20px;
  color: #fff;
}

.recommend__car-top h5 span i {
  font-size: 20px;
  color: #fff;
}

.recommend__car-bottom h4 {
  font-size: 1.3rem;
  margin: 10px 0px;
  font-size: 20px;
  color: #fff;
}

.recommend__car-other {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recommend__icons {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-size: 15px;
  color: #fff;
}

.recommend__icons p:nth-child(1) {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
